<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="12" md="auto" class="text-right mt-1 mt-md-0">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  @change="onColumnChange"
                ></n-column-visibility>
              </b-col>
              <b-col class="pl-0" cols="auto">
                <b-button
                  variant="success"
                  v-if="exportable()"
                  @click="exportExcel"
                >
                  {{ $t("button.export") }}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          :fields="fields"
          :visible-columns="visibleColumns"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :updatable="false"
          :deletable="false"
          :resource="resource"
          :route="route"
        >
          <template #cell(vinNumber)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-vehicle',
                params: { id: data.item.vehicleId },
              }"
              v-if="data.item.vinNumber"
            >
              {{ data.item.vinNumber }}
            </b-link>
            <span v-else>-</span>
          </template>
          <template #cell(reservePrice)="data">
            <span v-if="data.item.reservePrice">
              {{ data.item.reservePrice | currency }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(finalPrice)="data">
            <span v-if="data.item.finalPrice">
              {{ data.item.finalPrice | currency }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(auctionDate)="data">
            <span v-if="data.item.auctionDate">
              {{ data.item.auctionDate | formatDate }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(confirmAt)="data">
            <span v-if="data.item.confirmAt">
              {{ data.item.confirmAt | formatDate }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(dealer)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-dealer',
                params: { id: data.item.dealer.id },
              }"
              v-if="data.item.dealer"
            >
              {{ data.item.dealer.name }}
            </b-link>
            <span v-else>-</span>
          </template>
          <template #cell(bdPic)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-vehicle',
                params: { id: data.item.bdPic.id },
              }"
              v-if="data.item.bdPic"
            >
              {{ data.item.bdPic.name }}
            </b-link>
            <span v-else>-</span>
          </template>
          <template #cell(confirmBy)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-user',
                params: { id: data.item.confirmBy.id },
              }"
              v-if="data.item.confirmBy"
            >
              {{ data.item.confirmBy.name }}
            </b-link>
            <span v-else>-</span>
          </template>
          <template #cell(status)="data">
            <span
              :class="getTextClassByStatus(data.item.status)"
              v-if="data.item.confirmStatus == 1"
            >
              {{ $t(`status.${data.item.status}`) }}
            </span>
            <span
              v-else
              :class="{
                'text-danger': data.item.confirmStatus == 2,
                'text-warning': data.item.confirmStatus == 0,
              }"
            >
              {{ $t(`confirmStatus.${data.item.confirmStatus}`) }}
            </span>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap" v-if="data.item.confirmStatus == 0">
              <b-button
                v-b-tooltip.hover
                :title="$t('button.accept')"
                variant="success"
                pill
                size="sm"
                @click="confirm(data.item, 1)"
                v-if="$can('update', resource)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$t('button.reject')"
                variant="danger"
                pill
                size="sm"
                @click="confirm(data.item, 2)"
                v-if="$can('update', resource)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BBadge, BLink } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import { getTextClassByStatus, mapExportField } from "@/libs/helper";
import NColumnVisibility from "@/components/NColumnVisibility";
import moment from "moment";

const BidRepository = Repository.get("bid");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BLink,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NColumnVisibility,
    vSelect,
  },
  watch: {
    perPage() {
      this.list();
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.columns.biddingReport;
      },
      set(value) {
        this.$store.commit("columns/SET_BIDDING_REPORT_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        confirmStatus: this.$route.query.confirmStatus || null,
        bidDate: this.$route.query.bidDate || moment().format("YYYY-MM-DD"),
        status: this.$route.query.status || null,
        userBidId: Number(this.$route.query.userBidId) || null,
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        confirmStatus: this.$route.query.confirmStatus || null,
        bidDate: this.$route.query.bidDate || moment().format("YYYY-MM-DD"),
        status: this.$route.query.status || null,
        userBidId: Number(this.$route.query.userBidId) || null,
      },
      a: null,

      getTextClassByStatus,
    };
  },
  mounted() {
    this.a = document.createElement("a");
    document.body.appendChild(this.a);
    this.a.style = "display: none";

    this.getData();
  },
  methods: {
    exportable() {
      let exportable = true;
      for (let key in this.params) {
        if (this.query.hasOwnProperty(key)) {
          if (this.params[key] != this.query[key]) {
            exportable = false;
            break;
          }
        }
      }

      return exportable;
    },
    onColumnChange(field, checked) {
      const index = this.visibleColumns.findIndex((element) => {
        return element == field.key;
      });
      if (index != -1) {
        this.visibleColumns.splice(index, 1);
      } else {
        this.visibleColumns.push(field.key);
      }

      this.key++;
    },
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    list(page = 1) {
      this.query.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    exportExcel() {
      this.loading = true;
      BidRepository.exportExcel({
        ...this.query,
        searchFields: this.searchFields,
        fields: mapExportField(this.fields, this.visibleColumns),
      })
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }),
            url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `Bid Report - ${moment().format(
            "DD-MMM-YYYY"
          )}.xlsx`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: this.searchFields,
      };
      BidRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    confirm(item, status) {
      this.$bvModal
        .msgBoxConfirm(
          status == 1 ? this.$t("alert.acceptBid") : this.$t("alert.rejectBid"),
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: status == 1 ? "success" : "danger",
            okTitle:
              status == 1 ? this.$t("button.accept") : this.$t("button.reject"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.loading = true;
            BidRepository.update(item.id, { status: status })
              .then((response) => {
                this.getData();
              })
              .catch((error) => {})
              .then(() => {
                this.loading = false;
              });
          }
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "bidding-report";
    const route = "bidding-report";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
