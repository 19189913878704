export default [
  {
    key: 'bidDate',
    label: 'field.auctionDate',
    type: 'date-picker',
    operator: 'd=',
  },
  {
    key: 'userBidId',
    label: 'field.dealer',
    rules: 'required',
    type: 'asyn-single-selection',
    repository: 'paymentDealer',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'confirmStatus',
    label: 'field.confirmStatus',
    type: 'single-selection',
    clearable: true,
    translatable: true,
    selectionKey: 'value',
    selectionLabel: 'text',
    options: [
      { text: 'confirmStatus.0', value: '0' },
      { text: 'confirmStatus.1', value: '1' },
      { text: 'confirmStatus.2', value: '2' },
    ],
  },
  {
    key: 'status',
    label: 'field.status',
    type: 'single-selection',
    clearable: true,
    translatable: true,
    selectionKey: 'value',
    selectionLabel: 'text',
    options: [
      { text: 'status.1', value: '1' },
      { text: 'status.2', value: '2' },
      { text: 'status.3', value: '3' },
      { text: 'status.4', value: '4' },
      { text: 'status.5', value: '5' },
      { text: 'status.6', value: '6' },
      { text: 'status.7', value: '7' },
      { text: 'status.8', value: '8' },
      { text: 'status.9', value: '9' },
      { text: 'status.10', value: '10' },
      { text: 'status.11', value: '11' },
      { text: 'status.12', value: '12' },
      { text: 'status.13', value: '13' },
      { text: 'status.14', value: '14' },
      { text: 'status.15', value: '15' },
      { text: 'status.16', value: '16' },
    ],
  },
]