export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
    hideToggle: true,
  },
  {
    key: 'auctionDate',
    label: 'field.auctionDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'vinNumber',
    label: 'field.vinNumber',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'year',
    label: 'field.year',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'brandName',
    label: 'field.brand',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'model',
    label: 'field.model',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'engineCapacity',
    label: 'field.engineCapacity',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'transmission',
    label: 'field.transmission',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'variant',
    label: 'field.variant',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'reservePrice',
    label: 'field.reservePrice',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'finalPrice',
    label: 'field.finalPrice',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'dealer',
    label: 'field.dealer',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'colorName',
    label: 'field.color',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'zone',
    label: 'field.zone',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'lot',
    label: 'field.lot',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'bdPic',
    label: 'field.bdPic',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'totalBid',
    label: 'field.totalBid',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'totalDealer',
    label: 'field.totalDealer',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'confirmAt',
    label: 'field.checkAt',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'confirmBy',
    label: 'field.checkBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
  },
];
